import http from '../http'
import { ConfirmResetPasswordParams, Token } from '../types'
import { AUTH_URL } from './urls'

export const AuthApi = {
  login: async function (username: string, password: string): Promise<Token> {
    return await http.post(AUTH_URL.login, {
      username,
      password,
    })
  },

  logout: async function () {
    return await http.post(AUTH_URL.logout)
  },

  resetPassword: async function (email: string) {
    return await http.post(AUTH_URL.resetPassword, { email: email })
  },

  confirmResetPassword: async function (data: ConfirmResetPasswordParams) {
    return await http.post(`${AUTH_URL.resetPassword}confirm/`, data)
  },

  updatePassword: async function (data: {
    new_password1: string
    new_password2: string
  }) {
    return await http.post(AUTH_URL.updatePassword, data)
  },
}
