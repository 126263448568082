import { AutoComplete as AntDAutoComplete, Input } from 'antd'
import { CSSProperties, FunctionComponent, useState } from 'react'
import { AutocompleteApi } from '../services/api/autocomplete'
import { InputOptionArray } from '../services/types'
import { AUTOCOMPLETE_SECTION_TITLE } from '../constants/global'
import { useRouter } from 'next/router'

const { Search } = Input

export interface AutoCompleteProps {
  style?: CSSProperties
  placeholder?: string
}

const formatOptions = (type: string, data: any[]) => {
  return data.map((it) => ({
    ...it,
    type: type,
    label: it.name,
    value: `${it.name} ${AUTOCOMPLETE_SECTION_TITLE[type].singular}`,
  }))
}

const AutoComplete: FunctionComponent<AutoCompleteProps> = ({
  placeholder,
  style,
}) => {
  const router = useRouter()
  const [options, setOptions] = useState<InputOptionArray>([])
  const [searched, setSearched] = useState<boolean>(false)

  const handleSearch = async (searchText: string) => {
    setSearched(false)
    if (searchText.length >= 3) {
      try {
        let options = []
        const data = await AutocompleteApi(searchText)
        if (data) {
          for (let key in data) {
            if (data[key].length > 0) {
              options.push({
                label:
                  AUTOCOMPLETE_SECTION_TITLE[key][
                    data[key].length > 1 ? 'plural' : 'singular'
                  ],
                options: formatOptions(key, data[key]),
              })
            }
          }
        }
        setSearched(true)
        setOptions(options)
      } catch (e) {}
    }
  }

  const onSelect = (_value: string, option: any) => {
    const { type, id, code, name } = option
    switch (type) {
      case 'elements':
        router.push(`/element/${id}`)
        break

      case 'materials':
        router.push(`/materials/?id=${id}`)
        break

      case 'sub_elements':
        router.push(`/sub-element/${id}`)
        break

      case 'assemblies':
        router.push(
          `/materials/?material_section__assembly__code=${code}&material_section__assembly__name=${name}`
        )
        break
      default:
    }
  }

  return (
    <AntDAutoComplete
      options={options}
      onSearch={handleSearch}
      onSelect={onSelect}
      notFoundContent={searched ? 'No results' : ''}
      style={style}
    >
      <Search placeholder={placeholder} allowClear />
    </AntDAutoComplete>
  )
}

AutoComplete.defaultProps = {
  placeholder: '',
}

export default AutoComplete
