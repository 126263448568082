import { useState, useEffect } from 'react'
import { Input } from 'antd'
import { useRouter } from 'next/router'

interface SearchProps {
  placeholder?: string
  style?: any
  defaultValue?: string
}

const Search = ({ placeholder, style, defaultValue }: SearchProps) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const router = useRouter()

  const onSearch = (val: string, event: any) => {
    setLoading(true)
    router.push(`/search?q=${val}`)
  }

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [isLoading])

  return (
    <Input.Search
      style={style}
      placeholder={placeholder}
      onSearch={onSearch}
      defaultValue={defaultValue}
      loading={isLoading}
      allowClear
    />
  )
}

Search.defaultProps = {
  placeholder: 'Find something...',
}

export default Search
