import { FunctionComponent, ReactNode, useContext } from 'react'
import { Layout } from 'antd'
import { Header, Footer } from '../components'
import { UserContext } from '../contexts/UserContext'

const { Content } = Layout

export interface SimpleLayoutProps {
  logo?: 'footprint' | 'greenbook' | 'calculator'
  children: ReactNode
}

const SimpleLayout: FunctionComponent<SimpleLayoutProps> = (props) => {
  const { user } = useContext(UserContext)

  return (
    <Layout className='simple-layout'>
      <Header
        logo={props.logo || 'footprint'}
        hideSearchBox={true}
        hideWelcomeMenuIcon={true}
        loggedIn={user?.username ? true : false}
      />
      <Layout>
        <Content>{props.children}</Content>
      </Layout>
      <Footer />
    </Layout>
  )
}

SimpleLayout.defaultProps = {
  logo: 'footprint',
}

export default SimpleLayout
