import { FunctionComponent, useContext, useState } from 'react'
import { Layout, Menu, Affix, Button, Drawer, Typography } from 'antd'
import { colors } from '../../styles/theme'
import { useRouter } from 'next/router'
import { MenuContext } from '../contexts/MenuContext'
import { MenuItem } from '../services/types'
import { CONTACTS } from '../constants/global'
import { CommentOutlined, MenuOutlined } from '@ant-design/icons'
import Link from 'next/link'
import { NavContactSection, NavContactInfo } from './SideNav.styled'

const { SubMenu, ItemGroup } = Menu
const { Sider } = Layout
const { Title } = Typography

interface NavProps {
  mode?: 'inline' | 'vertical'
}

const NavContact: FunctionComponent = () => {
  return (
    <NavContactSection>
      <Title level={5}>
        <CommentOutlined /> Contact Us
      </Title>
      {/* phone */}
      <NavContactInfo>{CONTACTS.phone}</NavContactInfo>
      {/* email */}
      <NavContactInfo>
        <a href={`mailto:${CONTACTS.email}`}>{CONTACTS.email}</a>
      </NavContactInfo>
    </NavContactSection>
  )
}

const SideNav: FunctionComponent = () => {
  const router = useRouter()
  const { menu } = useContext(MenuContext)
  const [visible, setVisible] = useState<boolean>(false)

  const toggleNav = () => {
    setVisible(!visible)
  }

  const onTitleClick = (type: string, data: any) => {
    let { name, pk, sub_element, element, assembly } = data

    name = encodeURIComponent(name)
    let materialSectionParams: string = `material_section__name=${name}`
    const assemblyParams = assembly
      ? `material_section__assembly__name=${encodeURIComponent(assembly.name)}`
      : null
    const subElementParams = sub_element
      ? `material_section__assembly__sub_element__name=${encodeURIComponent(
          sub_element.name
        )}`
      : null
    const elementParams = element
      ? `material_section__assembly__sub_element__element__name=${encodeURIComponent(
          element.name
        )}`
      : null

    switch (type) {
      case 'elements':
        router.push(`/element/${pk}`)
        break

      case 'material_sections':
        router.push(
          `/materials/?${materialSectionParams}&${assemblyParams}&${subElementParams}&${elementParams}`
        )
        break

      case 'sub_elements':
        router.push(`/sub-element/${pk}`)
        break

      case 'assemblies':
        router.push(
          `/materials/?material_section__assembly__name=${name}&${subElementParams}&${elementParams}`
        )
        break

      case 'all':
        switch (data.type) {
          case 'elements':
            router.push(
              `/materials/?material_section__assembly__sub_element__element__name=${name}`
            )
            break
          case 'sub_elements':
            router.push(
              `/materials/?material_section__assembly__sub_element__name=${name}&${elementParams}`
            )
            break
          case 'assemblies':
            router.push(
              `/materials/?material_section__assembly__name=${name}&${subElementParams}&${elementParams}`
            )
            break
        }
        break
      default:
    }
    setVisible(false)
  }

  const renderMenuItem = (
    menuItems: MenuItem[],
    mode?: 'inline' | 'vertical'
  ) => {
    return menuItems.map((it: MenuItem) =>
      it.subMenu && it.subMenu.length > 0 ? (
        <SubMenu
          key={`${it.pk}-${it.type}-${it.code}`}
          title={it.name}
          onTitleClick={
            mode === 'vertical' ? () => onTitleClick(it.type, it) : undefined
          }
        >
          {mode === 'inline' &&
            (it.type === 'elements' || it.type === 'sub_elements') && (
              <Menu.Item
                key={`about-${it.pk}-${it.type}-${it.code}`}
                onClick={() => onTitleClick(it.type, it)}
              >
                {`About ${it.name}`}
              </Menu.Item>
            )}
          {it.subMenu.length > 1 && (
            <Menu.Item
              key={`all-${it.pk}-${it.type}-${it.code}`}
              onClick={() => onTitleClick('all', it)}
            >
              {`All ${it.name}`}
            </Menu.Item>
          )}
          {renderMenuItem(it.subMenu, mode)}
        </SubMenu>
      ) : (
        <Menu.Item
          key={`${it.pk}-${it.type}-${it.code}`}
          onClick={() => onTitleClick(it.type, it)}
        >
          {it.name}
        </Menu.Item>
      )
    )
  }

  const Nav = ({ mode }: NavProps) => {
    return (
      <>
        <Menu
          mode={mode}
          className='main-menu'
          subMenuOpenDelay={0.2}
          onMouseEnter={() => {
            document
              .getElementsByTagName('body')[0]
              .classList.add('overflow-hidden')
          }}
          onMouseLeave={() => {
            document
              .getElementsByTagName('body')[0]
              .classList.remove('overflow-hidden')
          }}
        >
          <ItemGroup title='Elements'>
            {menu && menu.length > 0 && renderMenuItem(menu, mode)}
          </ItemGroup>
          <ItemGroup title='Disclosures'>
            <Menu.Item key='standards-disclosures'>
              <Link href='/standards-disclosures/' passHref>
                <a>Standards Disclosures</a>
              </Link>
            </Menu.Item>
          </ItemGroup>
        </Menu>
        <NavContact />
      </>
    )
  }

  return (
    <>
      <Button
        className='menu-toggle visible-mobile'
        type='default'
        onClick={toggleNav}
      >
        <MenuOutlined />
      </Button>
      <Drawer
        title=''
        placement='left'
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ padding: 0 }}
      >
        <Nav mode='inline' />
      </Drawer>
      <Sider
        style={{ backgroundColor: colors.white }}
        width={280}
        className='hidden-mobile'
      >
        <Affix offsetTop={90}>
          <Nav mode='vertical' />
        </Affix>
      </Sider>
    </>
  )
}

export default SideNav
