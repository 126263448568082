const IconSave = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='14'
    height='18'
    viewBox='0 0 14 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    style={{ ...props.style, cursor: 'pointer' }}
  >
    <path
      d='M1.16667 0.666668H12.8333C13.0543 0.666668 13.2663 0.754465 13.4226 0.910746C13.5789 1.06703 13.6667 1.27899 13.6667 1.5V17.4525C13.6668 17.527 13.6469 17.6002 13.6091 17.6644C13.5713 17.7286 13.517 17.7815 13.4518 17.8176C13.3866 17.8537 13.3129 17.8716 13.2385 17.8695C13.164 17.8675 13.0914 17.8455 13.0283 17.8058L7 14.025L0.971669 17.805C0.908642 17.8446 0.836159 17.8666 0.761754 17.8687C0.68735 17.8708 0.613741 17.8529 0.548582 17.8169C0.483423 17.781 0.429092 17.7282 0.391238 17.6641C0.353385 17.6 0.333391 17.5269 0.333336 17.4525V1.5C0.333336 1.27899 0.421133 1.06703 0.577414 0.910746C0.733694 0.754465 0.945655 0.666668 1.16667 0.666668ZM12 2.33333H2V15.1933L7 12.0592L12 15.1933V2.33333Z'
      fill='currentColor'
    />
  </svg>
)

export default IconSave
