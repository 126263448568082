import { CSSProperties } from 'react'
import styled from 'styled-components'
import { device } from '../../styles/device'

export const CustomIconStyle: CSSProperties = {
  fontSize: 22,
  position: 'relative',
  top: 3,
}

export const Logo = styled.img`
  @media ${device.mobile} {
    width: 100px;
  }
`
