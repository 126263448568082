import { FunctionComponent } from 'react'
import { Row, Col, Card, Typography, Button } from 'antd'
import { useRouter } from 'next/router'

const { Title, Text } = Typography

export interface NewsCardProps {
  pk: number
  image?: string
  headline?: string
  body?: string
  create_date?: string
  author?: string
  short_description?: string
}

export interface LatestNewsProps {
  articles?: NewsCardProps[]
}

const NewsCard: FunctionComponent<NewsCardProps> = ({
  image,
  headline,
  create_date,
  pk,
  short_description,
}) => {
  const router = useRouter()
  return (
    <Card
      type='inner'
      hoverable={true}
      actions={[<Button size='large'>Read more</Button>]}
      onClick={() => pk && router.push(`/news/${pk}`)}
      className='card-flex'
    >
      {image && (
        <div
          className='card-image'
          style={{ backgroundImage: `url("${image}")` }}
        >
          <img alt={headline} src={image} />
        </div>
      )}
      {headline && <Title level={5}>{headline}</Title>}
      {short_description && (
        <div dangerouslySetInnerHTML={{ __html: short_description }} />
      )}
      {create_date && (
        <div style={{ marginTop: 10 }}>
          <Text type='secondary'>{create_date}</Text>
        </div>
      )}
    </Card>
  )
}

const LatestNews: FunctionComponent<LatestNewsProps> = ({ articles }) => {
  return articles && articles.length ? (
    <Row gutter={32}>
      {articles.map((article: NewsCardProps, i: number) => (
        <Col md={{ span: 24 }} lg={{ span: 8 }} key={i}>
          <NewsCard {...article} />
        </Col>
      ))}
    </Row>
  ) : null
}

LatestNews.defaultProps = {
  articles: [],
}

export default LatestNews
