import { Route } from 'antd/lib/breadcrumb/Breadcrumb'

export const DASHBOARD_BREADCRUMB: Route = {
  breadcrumbName: 'Dashboard',
  path: '/',
}

export const AUTOCOMPLETE_SECTION_TITLE: any = {
  materials: {
    plural: 'Materials',
    singular: 'Material',
  },
  assemblies: {
    plural: 'Assemblies',
    singular: 'Assembly',
  },
  sub_elements: {
    plural: 'Sub Elements',
    singular: 'Sub element',
  },
  elements: {
    plural: 'Elements',
    singular: 'Element',
  },
}

export const CONTACTS: any = {
  email: 'info@tgbcalc.com',
  phone: '+61 (0) 487 888 787',
}

export const NUMBER_ITEMS_PER_PAGE = [18, 24, 30]

export const CHART_COLORS = [
  '#4472C4',
  '#ED7D31',
  '#A5A5A5',
  '#FFC001',
  '#5B9BD5',
  '#70AD47',
  '#264478',
  '#9E480E',
  '#636363',
  '#997300',
  '#255E91',
  '#43682C',
  '#698ED0',
  '#F1975A',
  '#B7B7B7',
  '#FFCD32',
  '#7CAFDD',
  '#8CC169',
  '#335AA1',
  '#D26011',
  '#848484',
]
