module.exports.colors = {
  dark_blue: '#283A46',
  black: '#000000',
  mid_grey: '#9D9FA2',
  light_grey: '#BCBDC0',
  white: '#ffffff',
  deep_orange: '#F38021',
  mid_orange: '#F99D1C',
  yellow: '#FBC137',
  midnight_green: '#254951',
  greyish_teal: '#37616B',
  teal: '#2E7778',
  forest_green: '#116D57',
  moss_green: '#809975',
  soft_green: '#ABCB9B',
  jade: '#2EAC89',
  aqua: '#53BABA',
  pale_grey: '#f0f2f5',
  pale_blue: '#dde2e8',
  ebony_clay: '#24333D',
  nobel: '#b7b7b7',
  alabaster: '#f9f9f9',
  athens_gray: '#eef0f3',
  alto: '#d9d9d9'
}
