import { Card, Layout, PageHeader } from 'antd'
import { BreadcrumbProps } from 'antd/lib/breadcrumb'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import Link from 'next/link'
import { FunctionComponent, ReactNode } from 'react'
import { Footer, Header, SideNav } from '../components'

const { Content } = Layout

export interface BaseLayoutProps {
  title?: string
  subtitle?: string
  extra?: ReactNode
  breadcrumb?: BreadcrumbProps
  mainBackground?: string
  headerStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
}

export function itemRender(
  route: Route,
  _params: any,
  routes: Route[],
  _paths: any
) {
  const last = routes.indexOf(route) === routes.length - 1
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link href={route.path}>{route.breadcrumbName}</Link>
  )
}

const BaseLayout: FunctionComponent<BaseLayoutProps> = ({
  title,
  subtitle,
  extra,
  children,
  breadcrumb,
  mainBackground,
  headerStyle,
  contentStyle,
}) => {
  breadcrumb = {
    ...breadcrumb,
    itemRender,
  }
  return (
    <Layout>
      <Header loggedIn={true} />
      <Layout style={{ minHeight: 'calc(100vh - 90px)' }}>
        <SideNav />
        <Layout style={{ padding: '90px 24px 24px' }}>
          <Content>
            <PageHeader
              title={title}
              subTitle={subtitle}
              breadcrumb={breadcrumb}
              ghost={false}
              extra={extra}
              style={{
                marginTop: 24,
                marginBottom: 6,
                backgroundColor: mainBackground,
                ...headerStyle,
              }}
            />
            <Card style={{ backgroundColor: mainBackground, ...contentStyle }}>
              {children}
            </Card>
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default BaseLayout
