import styled from 'styled-components'
import { colors } from '../../styles/theme'


export const NavContactInfo = styled.p`
  line-height: 0.5;
`

export const NavContactSection = styled.section`
  padding: 20px;
  width: 100%;
  background-color: ${colors.pale_blue};
  text-align: center;

  a {
    text-decoration: underline;
    color: ${colors.black};
  }
`